import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'

function KeyServices() {
    const images = ["./Group 9439.png", "./Group 9440.png", "./Group 9442.png", "./Group 9443.png", "./Group 9444.png"]
    let [count, setCount] = useState(0);
    let [index, currentindex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => (prevCount >= images.length - 1 ? 0 : prevCount + 1));
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    const testKit = [
        {
            text: "Discover how billions of gut bacteria are affecting your physical and mental health",
            image: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/bugspeak.png",
            name: "Gut Microbiome Test",
            category: "BugSpeaks",
            id: "9545783"
        },
        {
            text: "Get deeper insights into your sugar metabolism and diet suitable for you",
            image: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/cgm.png",
            name: "Continuous Glucose Monitoring (CGM) ",
            category: "Blucon",
            id: "9545784"
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            currentindex(prevCount => (prevCount >= testKit.length - 1 ? 0 : prevCount + 1));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return <>
        <div className='about-section' id="keyservices">
            <section>
                <div className='wellness-section'>
                    <div className='wellnesstitletag'>
                        <img src='./Group 11925.png'></img>
                        <p>Key Services</p>
                        <img src='./Group 11926.png'></img>
                    </div>
                    <div className='doctor-Card'>
                        <div className='doc-img-card'>
                            <img src='./doctor profile 1.png'></img>
                        </div>
                        <div className='doctor-card-discription'>
                            <p>Teleconsultation Service</p>
                            <p>Remote & In-person Consultation <br />
                                with Ayurveda Health Coach</p>
                            <div className='doctor_modes'>
                                <p>Avaiable Modes:</p>
                                <div>
                                    <img src='./Group 7932.png'>
                                    </img>
                                    <p>Audio Call</p></div>
                                <p>|</p>
                                <div>
                                    <img src='./Group 7933.png'>
                                    </img>
                                    <p>Video Call</p></div>
                            </div>
                        

                        </div>

                    </div>

                </div>
            </section>
            <section>
                <div className='herbal-products-cards'>
                    <div className='doctor-card-discription'>
                        <p>Herbal Products</p>
                        <p>Explore Ayurvedic herbal products
                            <br />promoting holistic
                            wellness for modern lifestyle</p>
                  

                    </div>

                    <div className='herbal-img-card'>
                        <img src={images[count]}></img>
                    </div>


                </div>
            </section>
            {/* <section>
                <div className='herbal-products-cards'>


                    <div className='doctor-card-discription'>
                        <p>Diagnostic Tests</p>
                        <p>Empowering your health journey,<br/>
                            one test at a time.</p>
                     

                    </div>
                    <div className='testkit-card'>
                        <div className='testKit-img-card'>
                            <img src={testKit[index].image}></img>
                        </div>
                        <div className='testkitsection'>
                            <p>{testKit[index].category}</p>
                            <div class="cgm-button-container">
                                <div class="arrow">
                                    <img src="./Polygon 2.png" alt="Arrow" />
                                </div>
                                <div class="cgm-button">
                                    <span>{testKit[index].name}</span>
                                </div>
                            </div>
                            <p>{testKit[index].text} </p>
                        </div>
                    </div>

                </div>
            </section> */}
        </div>
    </>
}

export default KeyServices