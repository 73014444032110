import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Button, CircularProgress } from "@mui/material"; // Import CircularProgress for the loader
import axios from "axios";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Lottie from "lottie-react";
import successIcon from '../Compontents/success_icon.json'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BetaSignup() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const Submit = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length === 0) {
            setError("Please fill in the email address.");
        } else if (!emailRegex.test(email)) {
            setError("Please enter a valid email address.");
        } else {
            setLoading(true);
            try {
                let res = await axios.post("https://api.profile.ayurai.in/BetaSignup", {
                    email
                });
                if (res.status === 200) {
                    setEmail('');
                    setLoading(false);
                    handleOpen();
                }

            } catch (err) {
                setLoading(false);
                if (err.response) {
                    console.log(err.response.data);
                    setError(err.response.data);
                }
            }
        }
    };

    return (
        <>
            <Navbar />
            <div className='about-section'>
                <div className="BetaSignupPage">
                    <div className="BetaImg">
                        <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/mockup+screens/MOCKUP+BETA+SIGNUP.png' alt="Beta Signup" />
                    </div>
                    <div className="betaSignup">
                        <p>Beta Sign Up</p>
                        <p>Be the first to experience the Aiwell beta and discover innovative <br /> features designed to enhance your wellness journey!</p>
                        <div>
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" value={email} required onChange={(e) => {
                                setError('');
                                setEmail(e.target.value);
                            }} />
                            <p style={{ color: "#F15050", fontSize: "14px" }}>{error}</p>

                            <Button
                                style={{
                                    width: '100%',
                                    textTransform: 'none',
                                    borderRadius: '0',
                                    backgroundColor: '#DA6E0B',
                                    color: 'white',
                                    border: 'none',
                                    boxSizing: 'border-box',
                                    borderRadius: "5px",
                                    fontFamily: "'Poppins', sans-serif",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                                onClick={Submit}
                            >
                                {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : "Sign Up"} {/* Show loader or button text */}
                            </Button>
                        </div>
                    </div>
                    {
                        error === "Already registered" && (
                            <p>
                                You have already registered. Please use this link to download the app:
                                <a href='https://play.google.com/apps/internaltest/4701530936868033833'>link</a>
                            </p>
                        )
                    }

                </div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <div className='congratsdiv'>
                            <p id='congratesttitle'>Congratulations!</p>
                            <div className="successIcon">
                                <Lottie animationData={successIcon} loop={false}></Lottie>
                            </div>

                            <p id='congratestext1'>You've successfully signed up for our beta launch of Aiwell. You would have received an email.</p>
                        </div>
                    </DialogContent>
                </BootstrapDialog>
            </div>
            <Footer />
        </>
    );
}

export default BetaSignup;
