// Navbar.js
import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import Logo from '../Animations/Aiwell version 2 website logo.png';
import { useNavigate } from 'react-router-dom';

function Navbar({ scrollToSection }) {
   const navbar = useRef();
   const [sidebar, setSidebar] = useState(false);
   const Navigate = useNavigate()
   const scrolleffect = () => {
      if (navbar.current) {
         navbar.current.style.background = window.scrollY > 20 ? 'white' : '#F3FFFA';
         navbar.current.style.position = window.scrollY > 20 ? 'fixed' : 'static';
      }
   };

   useEffect(() => {
      window.addEventListener('scroll', scrolleffect);
      return () => window.removeEventListener('scroll', scrolleffect);
   }, []);

   const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }
      setSidebar(open);
   };

   const list = () => (
      <Box
         sx={{ width: 250 }}
         role="presentation"
         onClick={toggleDrawer(false)}
         onKeyDown={toggleDrawer(false)}
      >
         <List>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
               <CloseIcon onClick={toggleDrawer(false)} />
            </div>
            <ListItem>
               <ListItemButton onClick={() => scrollToSection('about')}>
                  <ListItemText primary="About us" />
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton onClick={() => scrollToSection('highlight-features')}>
                  <ListItemText primary="Key features" />
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton onClick={() => scrollToSection('wellness')}>
                  <ListItemText primary="Our Specialization" />
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton onClick={() => scrollToSection('articles')}>
                  <ListItemText primary="Articles" />
               </ListItemButton>
            </ListItem>
            <ListItem>
               <ListItemButton>
                  <Button style={{ textTransform: 'none', backgroundColor: '#0076BE', color: 'white' }} onClick={() => window.open('https://www.app.aiwell.in')}>
                     Sign up
                  </Button>
               </ListItemButton>
            </ListItem>
         </List>
      </Box>
   );

   return (
      <>
         <div className='Navbar' ref={navbar}>
            <div className='Logo' onClick={() => window.location.href = '#'}>
               <img src='../Aiwell logo 2.png' alt='Logo' />
            </div>
            <nav className='Navbar-contents'>
              
               <button onClick={() => Navigate('/')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none', color: 'inherit' }}>
                  <p>Home</p>
               </button>
            </nav>
            <div id='menubtn'>
               <button onClick={() => Navigate('/')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none', color: 'inherit' }}>
                  <p>Home</p>
               </button>
               {/* <MenuIcon onClick={toggleDrawer(true)} /> */}
            </div>
      
         </div>
      </>
   );
}

export default Navbar;
