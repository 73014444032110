import MobileScreen from '../Animations/MicrosoftTeams-image (1).png'
import { Button } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react';
import longevityimg from '../Animations/1icon.png'
import modernscienceimg from '../Animations/2icon.png'
import { Slide } from "react-awesome-reveal";
import rotationlogo from "../Animations/rotating logo.png"
import Appscreen from '../Animations/Mobile mockup 1.png'
import Android from '../Animations/tabler-icon-brand-android.png'
import IOS from '../Animations/tabler-icon-brand-apple.png'
import Logo from '../Animations/Logo.png'
import { Link, Element } from 'react-scroll';
import Playstore from '../Animations/playstore 1.png'
import Appstore from '../Animations/image 303.png'
import web from '../Animations/Group 11788.png'
import generalimg from '../Animations/General neutral image.png'
import arrowimg from '../Animations/Arrow.png'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function About() {
  const [animate, setAnimate] = useState(false);
  const healthImprovementRef = useRef(null);
  const slideInRef = useRef(null);
  const Navigate = useNavigate()

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleHealthImprovementVisibility = (entries, observer) => {
      entries.forEach(entry => {
        console.log('Health Improvement Entry:', entry); // Debugging log
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const healthImprovementObserver = new IntersectionObserver(handleHealthImprovementVisibility, {
      threshold: 0.1,
    });

    if (healthImprovementRef.current) {
      healthImprovementObserver.observe(healthImprovementRef.current);
    }

    return () => {
      if (healthImprovementRef.current) {
        healthImprovementObserver.unobserve(healthImprovementRef.current);
      }
    };
  }, [healthImprovementRef]);

  useEffect(() => {
    const handleSlideInVisibility = (entries, observer) => {
      entries.forEach(entry => {
        console.log('Slide-in Entry:', entry); // Debugging log
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const slideInObserver = new IntersectionObserver(handleSlideInVisibility, {
      threshold: 0.1,
    });

    if (slideInRef.current) {
      slideInObserver.observe(slideInRef.current);
    }

    return () => {
      if (slideInRef.current) {
        slideInObserver.unobserve(slideInRef.current);
      }
    };
  }, [slideInRef]);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const originalText = `Not feeling your best?<br />
  Find out what wellness strategies <br />
  can do for your daily energy <br />
  and happiness!`;

  const lines = originalText.split('<br />');
  let delayOffset = 0; // Initialize a delay offset

  const animatedText = lines.map((line, lineIndex) => {
    // Split the line into words
    const words = line.trim().split(/\s+/);

    const wordSpans = words.map((word, wordIndex) => (
      <span
        key={`${lineIndex}-${wordIndex}`}
        className="animated-word"
        style={{
          // Compute delay based on overall position of the word in the text
          animationDelay: `${delayOffset + wordIndex * 0.2}s`
        }}
      >
        {word + ' '}
      </span>
    ));

    // Update delayOffset for the next line
    delayOffset += words.length * 0.2;

    // Return the line wrapped in a fragment with words and a line break if needed
    return (
      <React.Fragment key={lineIndex}>
        {wordSpans}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });

  return <div className='about-section' id="abt">
    {/* <div className='betaclickhere'>
      <div><p>Be the first to experience innovative features & seamless performance with AiWell Beta launch</p></div>
      <div>
        <Button
          style={{
            width: '100%',
            textTransform: 'none',
            borderRadius: '0',
            backgroundColor: '#DA6E0B',
            color: 'white',
            border: 'none',
            boxSizing: 'border-box',
            paddingRight: "2rem",
            paddingLeft: "2rem",
            borderRadius: "5px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            padding: "10px"
          }}
          onClick={() => {
            Navigate('/BetaSignup')
          }}
        >
          Signup now
        </Button>
      </div>
    </div> */}
    <div className='app-main-page'>
      <div>
        <div>
          <p>Ayur Wellness</p>
          <p>360° Health Improvement</p>
          <p>Experience Ayurveda with AI-driven personalized recommendations for your unique body type and lifestyle, promoting balanced health and holistic well-being.</p>
        </div>

        <div className='app-main-page-btns'>
          <div>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                backgroundColor: '#DA6E0B',
                color: 'white',
                border: 'none',
                boxSizing: 'border-box',
                borderRadius: '5px',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: '10px'
              }}
              onClick={handleClickOpen}>Get App</Button>
          </div>
          <div>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                border: '1px solid #DA6E0B ',
                color: '#DA6E0B',
                boxSizing: 'border-box',
                // backgroundColor: "#FDFDFD",
                borderRadius: '5px',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: '10px'
              }}
              onClick={() => {
                window.open('https://www.app.aiwell.in/', '_blank');
              }}


            >app.aiwell.in</Button>
          </div>
        </div>

      </div>

      <div className='app-homepage'>
        <div className='slide-in' ref={slideInRef}>
          <p>Uniting 2,000+ year old tested
            oriental wisdom with modern science <br />healthy life</p>
        </div>
        <div className='Appscreenmock'>
          <img src={Appscreen} alt="App Screen" />
        </div>

        <div className='healthimprovement-container'>
          <div className='healthimprovement m-0' ref={healthImprovementRef}>
            <p>Combining Ayurveda & Modern Science</p>
          </div>
          {/* <div className='meterics-container'>
            <div className='meterics'>
              <img src={longevityimg} alt="Longevity" />
              <p>Longevity through integrative wellness</p>
            </div>
            <div className='meterics'>
              <img src={modernscienceimg} alt="Modern Science" />
              <p>Combining 2000+ year old time tested <br /> oriental wisdom with modern science</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>

    <div className='App-general-page'>
      <div>
        <img src={generalimg}></img>
      </div>
      <div className='App-general-section'>
        <p>Aiwell</p>
        <p>Scientifically Crafted for You</p>
        <p>Ayurvedic Wellness Personalized <br /> for Your Unique Constitution</p>
        <ul>
          <li>Integrative ( Ayurveda + Modern Markers) </li>
          <li>Holistic (Body + Mind)</li>
          <li>Personalized Health Coaching</li>
        </ul>
      </div>
    </div>
    <div className='discover-page'>
      <div>
        <img src={arrowimg}></img>
      </div>
      <div><p><span>Click Here</span> to discover your unique constitution</p></div>
    </div>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Avaiable on"}
      </DialogTitle>


      <div className='Avaiblemode'>
        <div>
          <Button
            style={{
              width: '100%',
              textTransform: 'none',
              borderRadius: '0',
              backgroundColor: "#3B362A",
              color: 'white',
              border: 'none',
              boxSizing: 'border-box',
              borderRadius: '5px',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              padding: '10px'
            }}
            onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=com.ayurai.aiwell&pcampaignid=web_share')
            }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
              <img src={Android} style={{ width: '2rem' }}>
              </img>
              <p>Android</p>
            </div>
          </Button>
        </div>

        <div>
          <Button style={{
            width: '100%',
            textTransform: 'none',
            borderRadius: '0',
            backgroundColor: "#3B362A",
            color: 'white',
            border: 'none',
            boxSizing: 'border-box',
            borderRadius: '5px',
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
            padding: '10px'
          }}
            onClick={() => {
              window.open('https://testflight.apple.com/join/TEwkUNfr')
            }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
              <img src={IOS} style={{ width: '2rem' }}>
              </img>
              <p>iOS</p>
            </div>
          </Button>
        </div>

      </div>



    </Dialog>
  </div>
}


export default About