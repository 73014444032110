import React from 'react';

function Mockups() {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className='about-section'>
      <section>
        <div className='wellness-card-bottom-section'>
          <p>Unlock Your Wellness: Tailored Solutions for Every Aspect of Your Health</p>
        </div>
        <div className='mockups-screen-page'>
          <div className='mockups-container'>
  
            {items.map((e, i) => (
              <div className='mockup-screen-item' key={i}>
                <img src={`https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/mockup+screens/${e}.png`} alt={`mockup-${e}`} />
              </div>
            ))}

            {items.map((e, i) => (
              <div className='mockup-screen-item' key={i + items.length}>
                <img src={`https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/mockup+screens/${e}.png`} alt={`mockup-${e}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Mockups;
