import React from 'react'
import { getDatabase, ref, onValue, } from "firebase/database";
import { db } from '../Compontents/firebase'
import { Link } from '@mui/material';
import ReactDOM from 'react-dom';
import Carousel from 'react-carousel-elasticss'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'

function Articles() {
    let queries = {
        xs: '(max-width: 320px)',
        sm: '(min-width: 720px)',
        md: '(min-width: 1024px)'
    }

    let [articles, setarticles] = useState([])

    let getData = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/articles`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setarticles(Object.values(data))
                }

                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    let tab = window.matchMedia(queries.md);
    let Mobile = window.matchMedia(queries.xs);

    return <div className='about-section' >
        {/* <section > */}
            <div className='wellness-section ' id='articles'>
                <div className='wellnesstitletag'>
                    <img src='./Group 11925.png'></img>
                    <p>Articles</p>
                    <img src='./Group 11926.png'></img>
                </div>
                <div className='articlesinfo'>
                    <p>Informative, Inspiring,<br /> Empowering <span>Ayurvedic Insights</span></p>
                </div>
                <div className='articles-section-carousel'>
                    <div className='Desktop'>
                        <Fade>
                            <Carousel itemsToShow={3} itemsToScroll={3} >
                                {articles.slice(0,5).map((e, i) => {
                                    return <div key={i}>
                                        <Link href={e?.link} target='_Blank' style={{ color: "black", textTransform: "none" }}>
                                            <div className='article-card'>
                                                <img src={e?.image}></img>
                                                <p>{e?.header}</p>
                                            </div>
                                        </Link>
                                    </div>
                                })}

                            </Carousel>
                        </Fade>

                    </div>
                    <div className='Mobile'>
                        <Fade>
                            <Carousel itemsToShow={1} itemsToScroll={1} className='Mobile'>
                                {articles.map((e, i) => {
                                    return <div key={i}>
                                        <Link href={e?.link} target='_Blank' style={{ color: "black", textTransform: "none" }}>
                                            <div className='article-card'>
                                                <img src={e?.image}></img>
                                                <p>{e?.header}</p>
                                            </div>
                                        </Link>
                                    </div>
                                })}

                            </Carousel>
                        </Fade>

                    </div>


                    <div className='Tablet'>
                        <Fade>
                            <Carousel itemsToShow={2} itemsToScroll={2} >
                                {articles.map((e, i) => {
                                    return <div key={i}>
                                        <Link href={e?.link} target='_Blank' style={{ color: "black", textTransform: "none" }}>
                                            <div className='article-card'>
                                                <img src={e?.image}></img>
                                                <p>{e?.header}</p>
                                            </div>
                                        </Link>
                                    </div>
                                })}

                            </Carousel>
                        </Fade>
                    </div>

                </div>
            </div>
        {/* </section> */}
    </div>
}

export default Articles