// Home.js
import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import About from './About';
import Hightlightfeatures from './Hightlightfeatures';
import Wellness from './Wellness';
import Mockups from './Mockups';
import KeyServices from './KeyServices';
import Articles from './Articles';
import Testimonials from './Testimonials';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navbar = useRef();
  const [sidebar, setSidebar] = useState(false);
  const Navigate = useNavigate();

  const aboutRef = useRef(null);
  const highlightFeaturesRef = useRef(null);
  const wellnessRef = useRef(null);
  const mockupsRef = useRef(null);
  const keyServicesRef = useRef(null);
  const articlesRef = useRef(null);
  const testimonialsRef = useRef(null);
  const footerRef = useRef(null);

  const scrolleffect = () => {
    if (navbar.current) {
      navbar.current.style.background = window.scrollY > 20 ? 'white' : '#F3FFFA';
      navbar.current.style.position = window.scrollY > 20 ? 'fixed' : 'static';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrolleffect);
    return () => window.removeEventListener('scroll', scrolleffect);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSidebar(open);
  };

  const scrollToSection = (section) => {
    switch (section) {
      case 'about':
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'highlightFeatures':
        highlightFeaturesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'wellness':
        wellnessRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'mockups':
        mockupsRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'keyServices':
        keyServicesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'articles':
        articlesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'testimonials':
        testimonialsRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <CloseIcon onClick={toggleDrawer(false)} />
        </div>
        <ListItem>
          <ListItemButton onClick={() => scrollToSection('about')}>
            <ListItemText primary="About us" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => scrollToSection('highlightFeatures')}>
            <ListItemText primary="Key Features" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => scrollToSection('wellness')}>
            <ListItemText primary="Wellness Packages" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => scrollToSection('articles')}>
            <ListItemText primary="Articles" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => scrollToSection('testimonials')}>
            <ListItemText primary="Testimonials" />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <Button
              style={{
                width: '100%',
                textTransform: 'none',
                borderRadius: '0',
                backgroundColor: '#DA6E0B',
                color: 'white',
                border: 'none',
                boxSizing: 'border-box',
                paddingRight: "2rem",
                paddingLeft: "2rem",
                borderRadius: "5px",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                padding: "10px"
              }}
              onClick={() => {
                Navigate('/BetaSignup');
              }}
            >
              Signup now
            </Button>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className='main-page'>
      <div className='Navbar' ref={navbar}>
        <div className='Logo' onClick={() => window.location.href = '#'}>
          <img src='../Aiwell logo 2.png' alt='Logo' />
        </div>
        <nav className='Navbar-contents'>
          <button onClick={() => scrollToSection('about')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none' }}>
            <p>About</p>
          </button>|
          <button onClick={() => scrollToSection('highlightFeatures')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none',  }}>
            <p>What Set Us Apart?</p>
          </button>|
          <button onClick={() => scrollToSection('wellness')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none', }}>
            <p>Wellness Packages</p>
          </button>|
          <button onClick={() => scrollToSection('keyServices')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none', }}>
            <p>Key features</p>
          </button>|
          <button onClick={() => scrollToSection('testimonials')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none',  }}>
            <p>Testimonials</p>
          </button>|
          <button onClick={() => scrollToSection('articles')} style={{ textDecoration: 'none', cursor: 'pointer', background: 'none', border: 'none' }}>
            <p>Articles</p>
          </button>

        </nav>

        <div id='menubtn'>
          <img src='../hamburger_web_blackcolor.png' onClick={toggleDrawer(true)}>
          </img>
        </div>

        <Drawer anchor={'right'} open={sidebar} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </div>

      <div ref={aboutRef} className="section">
        <About />
      </div>
      <div ref={highlightFeaturesRef} className="section">
        <Hightlightfeatures />
      </div>
      <div ref={wellnessRef} className="section">
        <Wellness />
      </div>
      <div ref={mockupsRef} className="section">
        <Mockups />
      </div>
      <div ref={keyServicesRef} className="section">
        <KeyServices />
      </div>
      <div ref={articlesRef} className="section">
        <Articles />
      </div>
      <div ref={testimonialsRef} className="section">
        <Testimonials />
      </div>
      <div ref={footerRef} className="section">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
