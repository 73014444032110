import React from 'react'
import { useState } from 'react';
import { Paper, TextField, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import ReactStars from 'react-stars'
import { Height } from '@mui/icons-material';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
function Testimonials() {

    const Testimonialinfo = [
        {
            name: "Venkatesh Vedagiri",
            img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/TestimonialImages/testimonials+1.png",
            rating: 4.5,
            review: "I've struggled with sleep for four years, spending much on treatments that never worked. Since joining the wellness program, I now sleep over 8 hours thanks to the AyurAI health coach who really listened and provided a solution that worked. Thank you so much!"
        }, {
            name: "Vikasini",
            img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/TestimonialImages/Vikashini+1.png",
            rating: 4.5,
            review: "I've been greatly benefitted after following the simple remedies and lifestyle practices advised by Dr.Harinee from Aiwell for intermittent dry cough and cramps."
        }
    ]

    return <div className='about-section'>
        <section>
            <div className='wellness-section'>
                <div className='wellnesstitletag'>
                    <img src='./Group 11925.png'></img>
                    <p>Testimonials</p>
                    <img src='./Group 11926.png'></img>
                </div>
                <div className='carousel-content'>
                    <Carousel animation="fade" navButtonsAlwaysVisible indicators autoPlay={false}>

                        {
                            Testimonialinfo.map((e) => {
                                return <div className='testimonial-card'>
                                    <div className='testimonial-img-card'>
                                        <img src={e.img} />
                                    </div>
                                    <div className='testimonial-discription'>
                                        <p>{e.name}</p>
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            value={4.5}
                                            color2={'#DA6E0B'}
                                             />
                                        <p>{e.review}</p>
                                    </div>
                                </div>
                            })
                        }

                    </Carousel>
                </div>
            </div>
        </section>
    </div>
}

export default Testimonials