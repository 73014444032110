import React from 'react'
import { useEffect, useState, useRef } from 'react';

function Hightlightfeatures() {
    const [index, setindex] = useState(0)

    const listdata = [{
        icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/dietary+selected.png",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/Dietary.png",
        name: "Dietary",
        listitem1: ["Nourish", "Thrive",
            "Transform"],
        listitem2: ["Foods to Include", "Foods to avoided"]
    }, {
        icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/yoga+selected.png",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/Yoga.png",
        name: "Yoga",
        listitem1: ["Balance", "Strength",
            "Transform"],
        listitem2: ["Renew", "Flourish"]
    }, {
        icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/meditation+selected.png",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/Meditation.png",
        name: "Meditation",
        listitem1: ["Mindful Zen", "Inner Peace"],
        listitem2: ["Serenity Now ", "Tranquil Mind"],

    }, {
        icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/meditation+selected-1.png",
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/What+set+us+apart/Shop.png",
        name: "Herbal Products",
        listitem1: ["Organic", "Stress Relief",
            "Transform"],
        listitem2: ["Immune Boosting", "Natural Ingredients"]
    }]

    return <div className='about-section' id='#about-section'>
        <section>
            <div className='highlightfeatures'>
                <div className='highlightfeatures-text'>
                    <p>What set us apart?</p>
                    <p>Tailored dosha-specific <br/> recommendations await you!</p>
                    <div className='listofhighlightfeatures'>
                        {
                            listdata.map((e, i) => {
                                return <div onClick={() => { setindex(i) }} className={index == i ? "featureselected" : "featureunselected"}>
                                    <img src={e.icon}></img>
                                    <p>{e.name}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className='app-screen'>
                    <div>
                        {
                            listdata[index].listitem2.map((e) => {
                                return <p>{e}</p>
                            })
                        }
                    </div>
                    <div className='Appscreenmock'>
                        <img src={listdata[index].img} alt="App Screen" />
                    </div>
                    <div className='m-0' >
                        {
                            listdata[index].listitem1.map((e) => {
                                return <p>{e}</p>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default Hightlightfeatures