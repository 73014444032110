import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Qr from '../Animations/MicrosoftTeams-image (6).png'
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import logo from "../Animations/Aiwell version 2 website logo.png"
function Footer() {
  return <div className='Footer'>
    <div>
      <img src={logo}></img>
      <p>The future of integrative health and wealth</p>
      <p>Fusing Science with Ayurvedic Principles

        to Customize Health, Wellness, and

        Inclusion in Healthcare.</p>
      <p>Follow us on</p>
      <div>
        <div>
          <InstagramIcon onClick={() => window.open('https://www.instagram.com/ayur.ai_/')} /></div>
        <div>
          <FacebookIcon onClick={() => window.open('https://www.facebook.com/ayuraiinnovations')} /></div>

        <div><i class="fa-brands fa-linkedin-in" onClick={() => window.open('https://www.linkedin.com/company/ayurai/')} ></i>   </div>
        <div>
          <i class="fa-brands fa-x-twitter" onClick={() => window.open('https://twitter.com/Ayur_AI')}></i>
        </div>

      </div>
      <p id='cpr'>© Copyright AyurAI. All Rights Reserved</p>
    </div>
    <div>
      <ul>
        <li><a href='#abt' style={{ textDecoration: "none", color: "black" }} ><p>About us</p></a></li>
        <li><a href='#Services' style={{ textDecoration: "none", color: "black" }}><p>Key Features</p></a></li>
        <li><a href='#specialization' style={{ textDecoration: "none", color: "black" }} ><p>Our Specialization</p></a></li>
        <li><a href='#articles' style={{ textDecoration: "none", color: "black" }}  ><p>Articles</p></a> </li>
      </ul>
    </div>
  
    <div>
      <p className='polices-content-head'>Contact us on</p>
      <div className='polices-content'>
        <p>Kanathur,
          Chennai, Tamilnadu
          India</p>
        <p>93631 35851</p>
        <p>contact@ayurai.io</p>
      </div>
    </div>
    <div>
      <div>
        <input type='text' placeholder='Enter Your Email Id'></input>
        <Button style={{ color: 'white', backgroundColor: "#DA6E0B", borderRadius: "5px", }} id='subtn'>Subscribe</Button>
      </div>
      <p>To get benefited with Aiwell</p>
      <div className='qrcode'>
        <img src={Qr}></img>
        <p>Scan this QR Code to <br />
          Download the App</p>
      </div>
    </div>
    <div>

    </div>
  </div>
}

export default Footer