import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';

function Wellness() {
    const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
    const wellnessInfo = [
        {
            wellness: "Respiratory Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Respiratoty.png",
            wellness_points: [
                "Reducing the chances of diseases like asthma, cough, cold, etc",
                "Promoting overall health"
            ]
        },
        {
            wellness: "Mental Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Mental.png",
            wellness_points: [
                "Ability to handle stress",
                "Overcome hardships and challenges",
                "Build connections"
            ]
        },
        {
            wellness: "Gastro Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Gastro.png",
            wellness_points: [
                "Maintaining healthy gut",
                "Preventing abdominal discomforts like acidity, bloating",
                "Strengthening Immunity"
            ]
        },
        {
            wellness: "Hair & Skin Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Hair+%26amp%3B+Skin.png",
            wellness_points: [
                "Maintain Healthier Skin & Hair",
                "Preventing Dandruff and infections"
            ]
        },
        {
            wellness: "Physical Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Physical.png",
            wellness_points: [
                "Exercise",
                "Good nutrition",
                "Proper sleep",
                "Sexual health, and",
                "Stress control"
            ]
        },
        {
            wellness: "Women Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Women.png",
            wellness_points: [
                "Hormonal and gynecological issues",
                "Bone health and mental health",
                "Healthy weight control"
            ]
        },
        {
            wellness: "Sexual Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Sexual.png",
            wellness_points: [
                "Improved overall health",
                "Preventing Hormonal imbalances"
            ]
        },
        {
            wellness: "Metabolic Wellness",
            wellness_icon: "https://aiwellassets.s3.ap-south-1.amazonaws.com/icons/Metabolic.png",
            wellness_points: [
                "Prevention of obesity, diabetes, and heart disease",
                "Improving BMI, blood sugar control and blood pressure"
            ]
        }
    ];


    return <>
        <div className='about-section'>

            <div className='wellness-s' id='wellnesssection'>
                <div>
                    <p>Wellness Packages</p>
                    <p>Unlock Your Wellness: Tailored Solutions for Every Aspect of Your Health</p>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                textTransform: 'none',
                                borderRadius: '0',
                                backgroundColor: '#DA6E0B',
                                color: 'white',
                                border: 'none',
                                boxSizing: 'border-box',
                                borderRadius: '5px',
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 500,
                                padding: '10px'
                            }}>Explore Now</Button>
                    </div>
                </div>
            </div>

        </div>
    </>
}

export default Wellness